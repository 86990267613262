<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>
            {{ $t("myreservations") }}
          </h1>
        </div>
      </div>
    </section>
    <div
      style="background-color: white; padding-top: 36px; padding-bottom: 150px"
    >
      <div class="container">
        <div class="text-right">
          <button type="button" class="btn btn-info mb-3" v-b-modal.modal-1>
            {{ $t("Add New") }}
          </button>
        </div>

        <b-modal id="modal-1" title="Add New" hide-footer>
          <div class="mb-3">
            <label for="id" class="form-label">
              {{ $t("Title") }} <b style="color: red;">*</b></label
            >
            <input
              type="text"
              class="form-control"
              id="id"
              v-model="body.title"
            />
          </div>
          <div class="mb-3">
            <label for="id" class="form-label">
              {{ $t("body") }} <b style="color: red;">*</b></label
            >
            <textarea class="form-control" id="id" v-model="body.xbody" />
          </div>
          <div class="text-center">
            <button class="btn btn-info" @click="add">
              {{ $t("send") }}
            </button>
          </div>
        </b-modal>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style="width:100px">#</th>
              <th scope="col">{{ $t("Title") }}</th>
              <th scope="col">{{ $t("Status") }}</th>
              <th scope="col">{{ $t("Day") }}</th>

              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item of list">
              <th scope="row">{{ $codePadding(item.id) }}</th>
              <td>{{ item.title }}</td>
              <td>{{ $t(item.status) }}</td>
              <td>{{ $durationFormat(item.createdAt) }}</td>

              <td class="text-center">
                <router-link :to="'onesupportTickets/' + item.id">
                  <i class="fa fa-eye"></i>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      body: {
        title: null,
        xbody: null,
      },
    };
  },

  methods: {
    add() {
      if (this.body.title && this.body.xbody) {
        this.$bvModal.hide("modal-1");
        const user = JSON.parse(localStorage.userKB);

        this.$http
          .post("supportTickets", { ...this.body, usersId: user.id })
          .then((res) => {
            this.$swal.fire({
              icon: "success",
              title: this.$t("Success"),
              text: this.$t("Success Done"),
              showConfirmButton: false,
              timer: 1500,
            });
            this.body = {
              title: null,
              xbody: null,
            };

            this.getDate();
          });
      } else {
        this.$swal.fire({
          icon: "error",
          title: this.$t("Error"),
          text: this.$t("Something has gone wrong"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getDate() {
      const user = JSON.parse(localStorage.userKB);

      this.$http.get("supportTickets?usersId=" + user.id).then((res) => {
        this.list = res.data.data;
      });
    },
  },
  async created() {
    this.getDate();
  },
};
</script>

<style></style>
